var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer bg-white py-4 d-flex flex-lg-column",
      attrs: { id: "kt_footer" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between",
          class: {
            "container-fluid": _vm.widthFluid,
            container: !_vm.widthFluid,
          },
        },
        [
          _c("div", { staticClass: "text-dark" }, [
            _c("span", { staticClass: "text-muted font-weight-bold mr-2" }, [
              _vm._v(" " + _vm._s(new Date().getFullYear()) + "  ©  "),
            ]),
            _c(
              "a",
              {
                staticClass: "text-dark-75 text-hover-primary",
                attrs: { href: "#", target: "_blank" },
              },
              [_vm._v(" Koperasi Wawasan Malaysia Berhad ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }