var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-mobile align-items-center",
      class: _vm.headerClasses,
      attrs: { id: "kt_header_mobile" },
    },
    [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: { alt: "Logo", src: _vm.headerLogo, width: "100" },
        }),
      ]),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.asideEnabled
          ? _c(
              "button",
              {
                staticClass: "btn p-0 burger-icon burger-icon-left",
                attrs: { id: "kt_aside_mobile_toggle" },
              },
              [_c("span")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            ref: "kt_header_mobile_topbar_toggle",
            staticClass: "btn btn-hover-text-primary p-0 ml-2",
            attrs: { id: "kt_header_mobile_topbar_toggle" },
          },
          [
            _c(
              "span",
              { staticClass: "svg-icon svg-icon-xl" },
              [
                _c("inline-svg", {
                  staticClass: "svg-icon",
                  attrs: { src: "media/svg/icons/General/User.svg" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }