var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "nokt_aside",
      staticClass:
        "noaside aside-left noaside-fixed d-flex flex-column flex-row-auto",
      attrs: { id: "nokt_aside" },
      on: { mouseover: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
    },
    [
      _c("KTBrand"),
      _c(
        "div",
        {
          staticClass: "noaside-menu-wrapper flex-column-fluid",
          attrs: { id: "nokt_aside_menu_wrapper" },
        },
        [
          _c(
            "div",
            {
              ref: "nokt_aside_menu",
              staticClass: "noaside-menu my-4",
              class: _vm.asideMenuClass,
              attrs: {
                id: "nokt_aside_menu",
                "data-menu-vertical": "1",
                "data-menu-dropdown-timeout": "500",
              },
            },
            [
              _c("perfect-scrollbar", {
                staticClass: "noaside-menu scroll",
                staticStyle: { "max-height": "90vh", position: "relative" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }