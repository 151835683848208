var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      {
        staticClass: "sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4",
      },
      [
        _c(
          "li",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.left",
                value: "Layout builder",
                expression: "'Layout builder'",
                modifiers: { hover: true, left: true },
              },
            ],
            staticClass: "nav-item mb-2",
          },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary",
                attrs: { href: "#", to: { name: "builder" } },
              },
              [_c("i", { staticClass: "flaticon2-gear" })]
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.left",
                value: "Documentation",
                expression: "'Documentation'",
                modifiers: { hover: true, left: true },
              },
            ],
            staticClass: "nav-item",
          },
          [_vm._m(0)]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning",
        attrs: {
          href: "https://keenthemes.com/metronic/?page=docs",
          target: "_blank",
        },
      },
      [_c("i", { staticClass: "flaticon2-telegram-logo" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }