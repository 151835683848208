<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Notifications -->
    <b-dropdown
      ref="notif"
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="
            btn btn-icon btn-clean btn-dropdown btn-lg
            mr-1
            pulse pulse-primary
          "
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <!-- <inline-svg src="media/svg/icons/Code/Compiling.svg" /> -->
            <inline-svg src="media/svg/icons/Communication/Chat6.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" ref="notif" class="min-w-md-350px">
        <form>
          <KTDropdownNotification
            @showNotificationDetailsDialog="showNotificationDetailsDialog"
            @onClose="onClick"
          ></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
    <NotificationDetailDialog
      v-model="showNotificationDetails"
      :notification="notification"
      :showBackButton="true"
      backButton='Tutup'
    ></NotificationDetailDialog>

    </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "@/core/services/i18n.service.js";
import NotificationDetailDialog from "@/view/pages/widgets/NotificationDetailDialog.vue";

export default {
  name: "KTTopbar",

  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      // Notifications
      showNotificationDetails: false,
      notification: {},
    };
  },

  components: {
    KTDropdownNotification,
    KTQuickUser,
    NotificationDetailDialog,
  },

  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },

    onClick() {
      // Close the menu and (by passing true) return focus to the toggle button
      this.$refs.notif?.hide(true)
    },

    showNotificationDetailsDialog(data) {
      // xxconsole.log('showNotificationDetailsDialog', data);
      this.notification = data;
      this.showNotificationDetails = true;
    },
  },

  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
};
</script>
