<template
>
  <div
  >
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4
        class="d-flex flex-center rounded-top"
      >
        <span
          class="text-white"
        >Notifikasi</span>
      </h4>

      <ul
        class="
          nav
          nav-bold
          nav-tabs
          nav-tabs-line
          nav-tabs-line-3x
          nav-tabs-line-transparent-white
          nav-tabs-line-active-border-success
          mt-3
          px-8
        "
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Mesej
          </a>
        </li>
      </ul>
    </div>

    <b-tabs
      class="hide-tabs"
      v-model="tabIndex"
    >
      <b-tab
      >
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 45vh; position: relative"
        >
          <div
            v-if="notifications.data != null || notifications.data != undefined"
          >
            <template
              v-for="(item, i) in notifications.data"
            >
              <a
                href="#"
                class="navi-item"
                v-bind:key="i"
              >
                <div
                  class="navi-link"
                >
                  <div
                    class="navi-text"
                    @click="showDetails(item)"
                  >
                    <div
                      class="font-weight-bold"
                    >
                      {{ getTitle(item.data) }}
                    </div>
                    <div
                      class="text-muted"
                    >
                      {{ item.created_at | formatDateTime }}
                    </div>
                  </div>
                </div>
              </a>
            </template>
          </div>

          <div
            v-else
          >
            <a
              href="#"
              class="navi-item"
            >
              <div
                class="navi-link"
              >
                <div
                  class="navi-text"
                >
                  <div
                    class="font-weight-bold"
                  >
                    {{ notifications.message }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </perfect-scrollbar>

        <div
          class="text-center"
        >
          <!-- <a href="/notification">
            <p class="text-decoration-underline text-primary">Lihat Semua</p>
          </a> -->
          <v-divider
            class="mt-0 mb-0"
            light
          ></v-divider>
          <v-btn
            text block
            color="primary"
            :disabled="isAtNotification"
            @click="viewNotificationPage"
          >Lihat Semua</v-btn>
        </div>
      </b-tab>

      <!-- <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new notifications.
        </div>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import {
  mapGetters,
} from "vuex";

import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "@/core/services/store/notification.module";

export default {
  name: "KTDropdownNotification",

  data() {
    return {
      tabIndex: 0,
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "notifications",
      "logs"
    ]),

    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },

    isAtNotification() {
      this.$emit("onClose");
      return this.$route.name == 'Notification'
    },

    // items() {
    //   return Array(this.notifications.data);
    // },

    // benched() {
    //   return 1;
    // },

    length() {
      return this.notifications.total;
    }
  },

  async mounted() {
    // Fetch notification from backend
    // await this.$store.dispatch(GET_NOTIFICATIONS).then((response) => {
    //   // Save notification data into vuex store
    //   if (response.success == true) {
    //     this.$store.commit(SET_NOTIFICATIONS, response);
    //   }
    // });
    await this.$store.dispatch(GET_NOTIFICATIONS);
  },

  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    viewNotificationPage() {
      var routeName = "Notification";
      if (this.$route.name == routeName) {
        this.$router.replace({
          name: routeName
        });
      }
      else {
        this.$router.push({
          name: routeName
        });
      }
    },

    showDetails(data) {
      this.$emit("showNotificationDetailsDialog", data);
    },

    getTitle(data) {
      return data?.title || data?.subject || "";
    },

  },

};
</script>
