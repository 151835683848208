var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "kt_header",
      staticClass: "header",
      class: _vm.headerClasses,
      attrs: { id: "kt_header" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex align-items-center justify-content-between",
        },
        [
          _c(
            "div",
            {
              ref: "kt_header_menu_wrapper",
              staticClass: "header-menu-wrapper header-menu-wrapper-left",
            },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.currentUserRoles[1]) + " "),
              ]),
              _vm.headerMenuEnabled
                ? _c(
                    "div",
                    {
                      ref: "kt_header_menu",
                      staticClass: "header-menu header-menu-mobile",
                      class: _vm.headerMenuClasses,
                      attrs: { id: "kt_header_menu" },
                    },
                    [_c("KTMenu")],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("KTTopbar"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }