var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top",
          style: { backgroundImage: "url(" + _vm.backgroundImage + ")" },
        },
        [
          _vm._m(0),
          _c(
            "ul",
            {
              staticClass:
                "\n        nav\n        nav-bold\n        nav-tabs\n        nav-tabs-line\n        nav-tabs-line-3x\n        nav-tabs-line-transparent-white\n        nav-tabs-line-active-border-success\n        mt-3\n        px-8\n      ",
              attrs: { role: "tablist" },
            },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-tab": "1",
                      "data-toggle": "tab",
                      href: "#",
                      role: "tab",
                    },
                    on: { click: _vm.setActiveTab },
                  },
                  [_vm._v(" Mesej ")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-tabs",
        {
          staticClass: "hide-tabs",
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c(
            "b-tab",
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "navi navi-hover scroll my-4",
                  staticStyle: { "max-height": "45vh", position: "relative" },
                },
                [
                  _vm.notifications.data != null ||
                  _vm.notifications.data != undefined
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.notifications.data, function (item, i) {
                            return [
                              _c(
                                "a",
                                {
                                  key: i,
                                  staticClass: "navi-item",
                                  attrs: { href: "#" },
                                },
                                [
                                  _c("div", { staticClass: "navi-link" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "navi-text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetails(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getTitle(item.data)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-muted" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatDateTime")(
                                                    item.created_at
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _c("div", [
                        _c(
                          "a",
                          { staticClass: "navi-item", attrs: { href: "#" } },
                          [
                            _c("div", { staticClass: "navi-link" }, [
                              _c("div", { staticClass: "navi-text" }, [
                                _c("div", { staticClass: "font-weight-bold" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.notifications.message) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-divider", {
                    staticClass: "mt-0 mb-0",
                    attrs: { light: "" },
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        block: "",
                        color: "primary",
                        disabled: _vm.isAtNotification,
                      },
                      on: { click: _vm.viewNotificationPage },
                    },
                    [_vm._v("Lihat Semua")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "d-flex flex-center rounded-top" }, [
      _c("span", { staticClass: "text-white" }, [_vm._v("Notifikasi")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }