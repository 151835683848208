<template>
  <ul class="menu-nav">
    <router-link v-if="dashboardAllowed" to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Senarai Permohonan</span>
        </a>
      </li>
    </router-link>

    <router-link v-if="newAppAllowed" to="/form" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-file-alt"></i>
          <span class="menu-text">Mohon Pembiayaan</span>
        </a>
      </li>
    </router-link>

    <router-link to="/faq" v-if="faqAllowed" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-question-circle"></i>
          <span class="menu-text">FAQ</span>
        </a>
      </li>
    </router-link>

    <router-link to="/help" v-if="helpAllowed" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon far fa-life-ring"></i>
          <span class="menu-text">Bantuan</span>
        </a>
      </li>
    </router-link>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <a class="menu-link" @click="onLogout">
        <i class="menu-icon fas fa-sign-out-alt"></i>
        <span class="menu-text">Log Keluar</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  name: "KTMenu",
  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "newAppAllowed",
      "dashboardAllowed",
      "faqAllowed",
      "helpAllowed",
    ]),
    isInArray: function () {
      return this.currentUserRoles.includes(value);
    },
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Login" }));
      // this.$store.replaceState({});
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
