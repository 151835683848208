var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "subheader py-2 py-lg-4 subheader-solid",
      class: _vm.subheaderClasses,
      attrs: { id: "kt_subheader" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap",
          class: {
            "container-fluid": _vm.widthFluid,
            container: !_vm.widthFluid,
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center flex-wrap mr-1" },
            [
              _c(
                "h5",
                { staticClass: "text-dark font-weight-bold my-2 mr-5" },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _c(
                "ul",
                {
                  staticClass:
                    "breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2",
                },
                [
                  _c(
                    "li",
                    { staticClass: "breadcrumb-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "subheader-breadcrumbs-home",
                          attrs: { to: "/" },
                        },
                        [
                          _c("i", {
                            staticClass: "flaticon2-shelter text-muted icon-1x",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.breadcrumbs, function (breadcrumb, i) {
                    return [
                      _c(
                        "li",
                        {
                          key: i + "-" + breadcrumb.id,
                          staticClass: "breadcrumb-item",
                        },
                        [
                          breadcrumb.route
                            ? _c(
                                "router-link",
                                {
                                  key: i,
                                  staticClass: "text-muted",
                                  attrs: { to: breadcrumb.route },
                                },
                                [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]
                              )
                            : _vm._e(),
                          !breadcrumb.route
                            ? _c(
                                "span",
                                { key: i, staticClass: "text-muted" },
                                [_vm._v(" " + _vm._s(breadcrumb.title) + " ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }