var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "topbar" },
    [
      _c(
        "b-dropdown",
        {
          ref: "notif",
          attrs: {
            size: "sm",
            variant: "link",
            "toggle-class": "topbar-item text-decoration-none",
            "no-caret": "",
            right: "",
            "no-flip": "",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n          btn btn-icon btn-clean btn-dropdown btn-lg\n          mr-1\n          pulse pulse-primary\n        ",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon svg-icon-xl svg-icon-primary",
                        },
                        [
                          _c("inline-svg", {
                            attrs: {
                              src: "media/svg/icons/Communication/Chat6.svg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "pulse-ring" }),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-dropdown-text",
            {
              ref: "notif",
              staticClass: "min-w-md-350px",
              attrs: { tag: "div" },
            },
            [
              _c(
                "form",
                [
                  _c("KTDropdownNotification", {
                    on: {
                      showNotificationDetailsDialog:
                        _vm.showNotificationDetailsDialog,
                      onClose: _vm.onClick,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("KTQuickUser"),
      _c("NotificationDetailDialog", {
        attrs: {
          notification: _vm.notification,
          showBackButton: true,
          backButton: "Tutup",
        },
        model: {
          value: _vm.showNotificationDetails,
          callback: function ($$v) {
            _vm.showNotificationDetails = $$v
          },
          expression: "showNotificationDetails",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }