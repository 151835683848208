var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "menu-nav" },
    [
      _vm.dashboardAllowed
        ? _c("router-link", {
            attrs: { to: "/dashboard" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active",
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate },
                            },
                            [
                              _c("i", { staticClass: "menu-icon fas fa-home" }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Senarai Permohonan"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3348251164
            ),
          })
        : _vm._e(),
      _vm.newAppAllowed
        ? _c("router-link", {
            attrs: { to: "/form" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active",
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate },
                            },
                            [
                              _c("i", {
                                staticClass: "menu-icon far fa-file-alt",
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Mohon Pembiayaan"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2276231694
            ),
          })
        : _vm._e(),
      _vm.faqAllowed
        ? _c("router-link", {
            attrs: { to: "/faq" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active",
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate },
                            },
                            [
                              _c("i", {
                                staticClass: "menu-icon far fa-question-circle",
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("FAQ"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1019709685
            ),
          })
        : _vm._e(),
      _vm.helpAllowed
        ? _c("router-link", {
            attrs: { to: "/help" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var href = ref.href
                    var navigate = ref.navigate
                    var isActive = ref.isActive
                    var isExactActive = ref.isExactActive
                    return [
                      _c(
                        "li",
                        {
                          staticClass: "menu-item",
                          class: [
                            isActive && "menu-item-active",
                            isExactActive && "menu-item-active",
                          ],
                          attrs: {
                            "aria-haspopup": "true",
                            "data-menu-toggle": "hover",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "menu-link",
                              attrs: { href: href },
                              on: { click: navigate },
                            },
                            [
                              _c("i", {
                                staticClass: "menu-icon far fa-life-ring",
                              }),
                              _c("span", { staticClass: "menu-text" }, [
                                _vm._v("Bantuan"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              523166920
            ),
          })
        : _vm._e(),
      _c(
        "li",
        {
          staticClass: "menu-item",
          attrs: { "aria-haspopup": "true", "data-menu-toggle": "hover" },
        },
        [
          _c("a", { staticClass: "menu-link", on: { click: _vm.onLogout } }, [
            _c("i", { staticClass: "menu-icon fas fa-sign-out-alt" }),
            _c("span", { staticClass: "menu-text" }, [_vm._v("Log Keluar")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }