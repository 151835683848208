<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <!-- v-if="isProfileEnabled" -->
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hai,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.short_name }}
      </span>
      <span
        class="symbol symbol-35 symbol-light-success"
      >
        <img
          v-if="false"
          alt="Pic"
          src="/media/users/default.jpg"
        />
        <span
          v-if="true"
          class="symbol-label font-size-h5 font-weight-bold"
        >
          {{ getUserFirstCharName }}
        </span>
      </span>
    </div>

    <!-- <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    > -->
      <!-- v-else -->
      <!-- <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hai,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.full_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" src="/media/users/default.jpg" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ getUserFirstCharName }}
        </span>
      </span>
    </div> -->

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!-- <div v-if="isProfileEnabled"> -->
        <!--begin::Header-->
        <div
          class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
        >
          <h3 class="font-weight-bold m-0">
            Profil Saya
          </h3>
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
        <!--end::Header-->

        <!--begin::Content-->
        <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative;"
        >
          <!--begin::Header-->
          <div class="d-flex align-items-center mt-5">
            <div class="symbol symbol-100 mr-5">
              <img
                class="symbol-label"
                src="/media/users/default.jpg"
                alt=""
              />
              <!-- <i class="symbol-badge bg-success"></i> -->
            </div>
            <div class="d-flex flex-column">
              <router-link
                to="/custom-pages/profile"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {{ getFullName }}
              </router-link>
              <div class="text-muted mt-1">{{ getNRIC }}</div>
              <div class="navi mt-2">
                <a href="#" class="navi-item">
                  <span class="navi-link p-0 pb-2">
                    <span class="navi-icon mr-1">
                      <span class="svg-icon svg-icon-lg svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/Communication/Mail-notification.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text text-muted text-hover-primary">
                      {{ currentUser.email }}
                    </span>
                  </span>
                </a>
              </div>
              <button class="btn btn-light-primary btn-bold" @click="onLogout">
                Log keluar
              </button>
            </div>
          </div>
          <!--end::Header-->
          <div class="separator separator-dashed mt-8 mb-5"></div>
          <!--begin::Nav-->
          <div class="navi navi-spacer-x-0 p-0">
            <!--begin::Item-->
            <router-link
              to="/profile/profile-1"
              @click.native="closeOffcanvas"
              href="#"
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Notification2.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <router-link to="/profile/profile-1">
                    <div class="font-weight-bold">Profile</div>
                  </router-link>
                  <div class="text-muted">
                    Tetapan akaun dan lain-lain
                  </div>
                </div>
              </div>
            </router-link>
            <!--end:Item-->
          </div>
          <!--end::Nav-->
          <div class="separator separator-dashed my-7"></div>
        </perfect-scrollbar>
        <!--end::Content-->
      </div>
    <!-- </div> -->
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {
  mapGetters,
} from "vuex";

import {
  LOGOUT,
} from "@/core/services/store/auth.module";

import {
  PURGE_ALL,
} from "@/core/services/store/application.module";

import {
  GET_USER,
  PURGE_ALL_USER,
} from "@/core/services/store/user.module";

import {
  PURGE_ALL_FORM,
} from "@/core/services/store/form.module";

import {
  PURGE_ALL_CHECKLIST,
} from "@/core/services/store/mediaupload.module";

import {
  PURGE_LOOKUPS,
} from "@/core/services/store/lookup.module";

import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "getUserFirstCharName",
    ]),

    getFullName() {
      return this.currentUser.full_name;
    },
    getNRIC() {
      return this.currentUser.nric_display;
    },
    isProfileEnabled() {
      const profileState = !!(this.currentUser.email_verified || this.currentUser.phone_verified);
      // if (profileState) {
      //   KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
      //   console.log('kt_quick_user was initialized');
      // }
      return profileState;
    }
  },

  mounted() {
    // Init Quick User Panel
    // this.$store.dispatch(GET_USER);

    // if (this.isProfileEnabled) {
      KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    // }
    // else {
    //   console.log('kt_quick_user was NOT initialized');
    // }
  },

  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT)
        .then(() => {
          this.$store.commit(PURGE_ALL);
          this.$store.commit(PURGE_ALL_FORM);
          this.$store.commit(PURGE_ALL_CHECKLIST);
          this.$store.commit(PURGE_ALL_USER);
          this.$store.commit(PURGE_LOOKUPS);
          this.$router.push({ name: "Login" });
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
};
</script>
