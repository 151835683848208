var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "nokt_brand",
      staticClass: "brand flex-column-auto",
      attrs: { id: "nokt_brand" },
    },
    [
      _c(
        "div",
        { staticClass: "nobrand-logo" },
        [
          _c("router-link", { attrs: { to: "/dashboard" } }, [
            _c("img", {
              attrs: { src: _vm.siteLogo(), alt: "Logo", width: "100" },
            }),
          ]),
        ],
        1
      ),
      _vm.allowMinimize
        ? _c("div", { staticClass: "brand-tools" }, [
            _c(
              "button",
              {
                ref: "kt_aside_toggle",
                staticClass: "brand-toggle btn btn-sm px-0",
                attrs: { id: "kt_aside_toggle" },
              },
              [
                _c(
                  "span",
                  { staticClass: "svg-icon svg-icon svg-icon-xl" },
                  [
                    _c("inline-svg", {
                      staticClass: "svg-icon",
                      attrs: {
                        src: "media/svg/icons/Navigation/Angle-double-left.svg",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }