var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topbar-item" }, [
    _c(
      "div",
      {
        staticClass:
          "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2",
        attrs: { id: "kt_quick_user_toggle" },
      },
      [
        _c(
          "span",
          {
            staticClass:
              "text-muted font-weight-bold font-size-base d-none d-md-inline mr-1",
          },
          [_vm._v(" Hai, ")]
        ),
        _c(
          "span",
          {
            staticClass:
              "text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3",
          },
          [_vm._v(" " + _vm._s(_vm.currentUser.short_name) + " ")]
        ),
        _c("span", { staticClass: "symbol symbol-35 symbol-light-success" }, [
          false
            ? _c("img", {
                attrs: { alt: "Pic", src: "/media/users/default.jpg" },
              })
            : _vm._e(),
          true
            ? _c(
                "span",
                { staticClass: "symbol-label font-size-h5 font-weight-bold" },
                [_vm._v(" " + _vm._s(_vm.getUserFirstCharName) + " ")]
              )
            : _vm._e(),
        ]),
      ]
    ),
    _c(
      "div",
      {
        ref: "kt_quick_user",
        staticClass: "offcanvas offcanvas-right p-10",
        attrs: { id: "kt_quick_user" },
      },
      [
        _vm._m(0),
        _c(
          "perfect-scrollbar",
          {
            staticClass: "offcanvas-content pr-5 mr-n5 scroll",
            staticStyle: { "max-height": "90vh", position: "relative" },
          },
          [
            _c("div", { staticClass: "d-flex align-items-center mt-5" }, [
              _c("div", { staticClass: "symbol symbol-100 mr-5" }, [
                _c("img", {
                  staticClass: "symbol-label",
                  attrs: { src: "/media/users/default.jpg", alt: "" },
                }),
              ]),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "font-weight-bold font-size-h5 text-dark-75 text-hover-primary",
                      attrs: { to: "/custom-pages/profile" },
                    },
                    [_vm._v(" " + _vm._s(_vm.getFullName) + " ")]
                  ),
                  _c("div", { staticClass: "text-muted mt-1" }, [
                    _vm._v(_vm._s(_vm.getNRIC)),
                  ]),
                  _c("div", { staticClass: "navi mt-2" }, [
                    _c(
                      "a",
                      { staticClass: "navi-item", attrs: { href: "#" } },
                      [
                        _c("span", { staticClass: "navi-link p-0 pb-2" }, [
                          _c("span", { staticClass: "navi-icon mr-1" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-lg svg-icon-primary",
                              },
                              [
                                _c("inline-svg", {
                                  attrs: {
                                    src: "media/svg/icons/Communication/Mail-notification.svg",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "navi-text text-muted text-hover-primary",
                            },
                            [_vm._v(" " + _vm._s(_vm.currentUser.email) + " ")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light-primary btn-bold",
                      on: { click: _vm.onLogout },
                    },
                    [_vm._v(" Log keluar ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "separator separator-dashed mt-8 mb-5" }),
            _c(
              "div",
              { staticClass: "navi navi-spacer-x-0 p-0" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "navi-item",
                    attrs: { to: "/profile/profile-1", href: "#" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeOffcanvas.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "navi-link" }, [
                      _c(
                        "div",
                        { staticClass: "symbol symbol-40 bg-light mr-3" },
                        [
                          _c("div", { staticClass: "symbol-label" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-md svg-icon-success",
                              },
                              [
                                _c("inline-svg", {
                                  attrs: {
                                    src: "media/svg/icons/General/Notification2.svg",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "navi-text" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/profile/profile-1" } },
                            [
                              _c("div", { staticClass: "font-weight-bold" }, [
                                _vm._v("Profile"),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "text-muted" }, [
                            _vm._v(" Tetapan akaun dan lain-lain "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "separator separator-dashed my-7" }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "offcanvas-header d-flex align-items-center justify-content-between pb-5",
      },
      [
        _c("h3", { staticClass: "font-weight-bold m-0" }, [
          _vm._v(" Profil Saya "),
        ]),
        _c(
          "a",
          {
            staticClass: "btn btn-xs btn-icon btn-light btn-hover-primary",
            attrs: { href: "#", id: "kt_quick_user_close" },
          },
          [_c("i", { staticClass: "ki ki-close icon-xs text-muted" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }