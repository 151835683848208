var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "kt_scrolltop",
      staticClass: "scrolltop",
      attrs: { id: "kt_scrolltop" },
    },
    [
      _c(
        "span",
        { staticClass: "svg-icon" },
        [
          _c("inline-svg", {
            attrs: { src: "media/svg/icons/Navigation/Up-2.svg" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }